import router from '@/router';
import { reactive, computed } from 'vue';
import { axiosInstance } from '@/axios';

export const userResource = reactive({
  // Use Axios for making HTTP requests
  getUser: async () => {
    try {
      const response = await axiosInstance.get('frappe.auth.get_logged_user');
      return response.data;
    } catch (error) {
      handleAuthenticationError(error);
      throw error;
    }
  },
  // ... Add other resource methods as needed
});

// Error handler for authentication-related errors
function handleAuthenticationError(error) {
  if (error && error.response && error.response.status === 401) {
    router.push({ name: 'LoginPage' });
  }
}

export const user = reactive({
  // Use a computed property to get user data
  data: computed(() => userResource.getUser()),
  // Add other user-related properties as needed
});
