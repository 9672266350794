// session.js

import router from '@/router';
import { computed, reactive } from 'vue';
import { axiosInstance } from '@/axios';
import { userResource } from './user';
import eventBus from '../eventBus';

export function isSystemUser() {
  const cookies = new URLSearchParams(document.cookie.split('; ').join('&'))
  let _system_user = cookies.get('system_user') == 'yes'
  return _system_user
}

export function getFullName() {
  const cookies = new URLSearchParams(document.cookie.split('; ').join('&'))
  let _fullName = cookies.get('full_name')
  return _fullName
}

export function getUserImage() {
  const cookies = new URLSearchParams(document.cookie.split('; ').join('&'))
  if (cookies.get('user_image')) {
    return cookies.get('user_image');
  }
}

export function sessionUser() {
  const cookies = new URLSearchParams(document.cookie.split('; ').join('&'))
  let _sessionUser = cookies.get('user_id')
  if (_sessionUser === 'Guest') {
    _sessionUser = null
  }
  return _sessionUser
}


export const forgotPassword = async ({ email }) => {
  try {
    const response = await axiosInstance.post('frappe.core.doctype.user.user.reset_password', { user: email });
    // Handle success
  } catch (error) {
    // Handle error
  }
};

export const handleAuthenticationError = (error) => {
  if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
    session.user = sessionUser();
    session.full_name = null;
    session.user_image = null;
    router.replace({ name: 'Login' });
    eventBus.value.$emit(
      'show-toast',
      'Session Expired!', // message
      'error', // color
      'mdi-alert-circle', // icon
      5000, // timeout
    );
  }
}

export const session = reactive({
  login: async ({ email, password }) => {
    try {
      const response = await axiosInstance.post('login', {
        usr: email,
        pwd: password
      }, { blockui: true });

      userResource.getUser();
      session.user = sessionUser();
      session.full_name = getFullName();
      session.user_image = getUserImage();
      try {
        const response = await axiosInstance.post(
            "onex_customisations.onex_customisations.api.check_customer_password", {
                email: email
            }
        );

        if(response.data['message'] == 1) {
            session.force_reset_password = true;
        }
      } catch (error) {
        // Handle error
        console.log(error)
        throw error;
      }
      router.replace('/dashboard');
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
  logout: async () => {
    try {
      const response = await axiosInstance.post('logout', null, { blockui: true });
      session.user = sessionUser();
      session.full_name = null;
      session.user_image = null;
      router.replace({ name: 'Login' });
    } catch (error) {
      throw error;
    }
  },
  user: sessionUser(),
  full_name: getFullName(),
  user_image: getUserImage(),
  isLoggedIn: computed(() => !!session.user),
  force_reset_password: false,
});