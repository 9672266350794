<template>
    <div v-if="commonStore.overlayVisible" class="overlay">
        <!-- You can customize the overlay content here -->
        <div class="overlay-content">
            {{ commonStore.overlayMessage || 'Please Wait...' }}
        </div>
    </div>
</template>

<script setup>
import { useCommonStore } from '@/store/commonStore';

const commonStore = useCommonStore();


</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Semi-transparent white background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Adjust z-index based on your UI */
}

.overlay-content {
    /* Customize the styling of the overlay content */
    background: rgb(0, 0, 0);
    opacity: 0.7;
    padding: 10px;
    border-radius: 10px;
    letter-spacing: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    font-weight: 500;
    color: white;
}
</style>
