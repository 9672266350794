<script setup>
import { RouterLink } from 'vue-router';
import {
    session
} from '/src/data/session'

const logout = () => {
    session.logout();
}

$(document).ready(function () {


    $("#userdropdown").click(function () {
        $("#UserDropdownContent").toggle();
    })

});

$(window).scroll(function () {
    if ($(this).scrollTop() > 20) {
        $('#myBtn').fadeIn(200);
    } else {
        $('#myBtn').fadeOut(200);
    }
});
$('#myBtn').click(function (event) {
    event.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 500);
});

// SideNav Js
function openNav() {
    // document.getElementById("mySidenav").style.width = "250px";
    $("#mySidenav").css('width', '250px')
}

function closeNav() {
    $("#mySidenav").css('width', '0px')
}
</script>
<template>
    <div class="elementor elementor-111 elementor-location-header">
        <div class="elementor-section-wrap">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-110da29 elementor-section-full_width elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                data-id="110da29" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;tf_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-2bc122a"
                        data-id="2bc122a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-941a3cd elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="941a3cd" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container padding10px">
                                    <link rel="stylesheet" href="/src/assets/Assets/css/widget-icon-list.min.css">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <a href="#">
                                                <span class="elementor-icon-list-icon">
                                                    <i aria-hidden="true" class="far fa-envelope"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Email </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-8566471"
                        data-id="8566471" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-722851a elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="722851a" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container padding10px">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">

                                            <a href="https://api.whatsapp.com/send?phone=9844104631" target="_blank">
                                                <span class="elementor-icon-list-icon">
                                                    <i aria-hidden="true" class="fa fa-whatsapp"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Whatsapp </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-cb57b72"
                        data-id="cb57b72" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-8aabd26 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="8aabd26" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container padding10px">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <a href="tel:+971-4-8842008">
                                                <span class="elementor-icon-list-icon">
                                                    <i aria-hidden="true" class="fas fa-phone-alt"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Tel No</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-1262fa6"
                        data-id="1262fa6" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-7c46269"
                        data-id="7c46269" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-5af5ca6"
                        data-id="5af5ca6" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5e5ef4b elementor-shape-circle elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                                data-id="5e5ef4b" data-element_type="widget" data-widget_type="social-icons.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-social-icons-wrapper elementor-grid padding10px">
                                        <span class="elementor-grid-item">
                                            <a class="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-97619eb"
                                                href="https://www.facebook.com/onexcouriers" target="_blank">
                                                <span class="elementor-screen-only">Facebook</span>
                                                <i class="fab fa-facebook"></i>
                                            </a>
                                        </span>
                                        <span class="elementor-grid-item">
                                            <a class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-0305439"
                                                href="https://www.instagram.com/onexcouriers/" target="_blank">
                                                <span class="elementor-screen-only">Twitter</span>
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                        </span>
                                        <span class="elementor-grid-item">
                                            <a class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-e3ea90e"
                                                href="https://www.linkedin.com/company/onex-courier/" target="_blank">
                                                <span class="elementor-screen-only">Youtube</span>
                                                <i class="fab fa-brands fa-linkedin-in"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                class="elementor-section elementor-top-section elementor-element elementor-element-9a29653 elementor-section-full_width elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                data-id="9a29653" data-element_type="section" data-settings="{&quot;tf_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-985625e"
                        data-id="985625e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element  elementor-widget elementor-widget-image" data-id="e9b9590"
                                data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <RouterLink to="dashboard"><img width="150"
                                            src="/src/assets/Assets/images/one-x-logo.gif"
                                            class="attachment-large size-large" alt="ONEX" loading="lazy"></RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-b9f5d7c"
                        data-id="b9f5d7c" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-650b11c elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                                data-id="650b11c" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img width="4" height="80" src="/src/assets/Assets/images/line-1-copy.png"
                                        class="attachment-full size-full" alt="" loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-677a15e"
                        data-id="677a15e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-4613ca3 elementor-nav-menu__align-center elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                                data-id="4613ca3" data-element_type="widget"
                                data-settings="{&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i class=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}"
                                data-widget_type="nav-menu.default">
                                <div class="elementor-widget-container">
                                    <div class="logo-text">
                                        <h5><span class="logo-text-one">ON</span><span class="logo-text-two">EX</span>
                                            Courier &amp; Logistics</h5>
                                    </div>
                                    <link href="/src/assets/Assets/css/widget-nav-menu.min%EF%B9%96V=1.3.css"
                                        rel="stylesheet">
                                    <nav migration_allowed="1" migrated="0" role="navigation"
                                        class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                                        <ul id="menu-1-4613ca3" class="elementor-nav-menu">

                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-105">
                                                <RouterLink to="dashboard" class="elementor-item menu-link">Home
                                                </RouterLink>
                                            </li>

                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-952">
                                                <RouterLink to="shipment-tracking" class="elementor-item menu-link">Shipment
                                                    Tracking</RouterLink>
                                            </li>
                                            <!-- MORE DROPDOWN REFACTOR FOR BETTER VIEW -->
                                            <!-- <li class="more-dropdown">
                                                <a href="#">More</a>
                                                <ul class="more-dropdown">

                                                    <li><a href="Web/CreateShipment.html">Create Shipment</a></li>
                                                    <li><a href="shipment-tracking">Shipment Tracking</a>
                                                    </li>
                                                    <li><a href="Web/ShipmentHistory.html">Shipment History</a></li>
                                                    <li><a href="Web/CustomerProfile.html">My Profile</a></li>
                                                    <li><a href="Web/RaiseQuery.html">Raise Query</a></li>
                                                    <li><a href="Web/ShipmentCancellation.html">Cancel Shipment</a>
                                                    </li>
                                                    <li><a href="Web/AddressBook.html">Address Book</a></li>
                                                    <li><a href="Web/UnbilledShipment.html">Unbilled Shipment</a>
                                                    </li>
                                                    <li><a href="Web/OutstandingAmount.html">Outstanding Amount</a>
                                                    </li>
                                                    <li><a href="Web/LedgerDetail.html">Ledger Detail</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/MyInvoices">My
                                                            Invoices</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/SchedulePickup">Schedule
                                                            Pickup</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/CancelPickup">Cancel
                                                            Pickup</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/ReschedulePickup">Reschedule
                                                            Pickup</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/DownloadForms">Download
                                                            Forms</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/RaiseRFQ">Raise
                                                            RFQ</a></li>
                                                    <li><a href="http://client.onexcouriers.com/Web/RaiseTicket">Raise
                                                            Ticket</a></li>




                                                </ul>
                                            </li> -->
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-105">
                                                <RouterLink to="notification-detail" class="elementor-item menu-link flex items-center">
                                                    <i class="fa fa-bell mr-2"></i>
                                                        <span class="label label-warning hidden md:block" style="height:30px;"><sup><span id="spnNotification" style="color:red;font-weight:bold;font-size:25px;">0</span></sup></span>
                                                </RouterLink>
                                            </li>
                                        </ul>

                                    </nav>


                                </div>
                            </div>
                        </div>
                    </div>










                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-7c2da68"
                        data-id="7c2da68" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-71e3a85 elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                                data-id="71e3a85" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img width="4" height="80" src="/src/assets/Assets/images/line-1-copy.png"
                                        class="attachment-full size-full" alt="" loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-096f79a"
                        data-id="096f79a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-8f1f25c elementor-align-left elementor-widget elementor-widget-button"
                                data-id="8f1f25c" data-element_type="widget" data-widget_type="button.default">
                                <div class="elementor-widget-container" style="margin: 4px -4px 0px 0px;">
                                    <ul class="navbar-nav ml-auto">
                                        <li class="nav-item">
                                            <div class="dropdown" id="userdropdown">
                                                <div class="dropbtn nav-link">
                                                    <a>
                                                        <img v-if="session.user_image" :src="session.user_image" alt=""
                                                            style="border-radius:50%; border: 1px solid #898585;" />
                                                        
                                                        <!-- Display a separate section here when user_image is null or empty -->
                                                        <img v-if="!session.user_image" src="/src/assets/Assets/images/profile-img.png" alt=""
                                                            style="border-radius:50%; border: 1px solid #898585;" />

                                                        {{ session.full_name }}
                                                        <i class="fa fa-caret-down"></i>

                                                    </a>
                                                </div>
                                                <div class="dropdown-content" id="UserDropdownContent">
                                                    <RouterLink to="change-password">Change
                                                        Password<i class="fas fa-key  pl-2"></i></RouterLink>

                                                    <a @click="logout()">Logout<i class="fas fa-sign-out-alt  pl-2"></i></a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                class="elementor-section elementor-top-section elementor-element elementor-element-87aeb3f elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no"
                data-id="87aeb3f" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;tf_sticky&quot;:&quot;no&quot;}"
                style="width: 1728px; left: 0px;">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9c0342e"
                        data-id="9c0342e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-e0ad91e elementor-widget elementor-widget-image"
                                data-id="e0ad91e" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img width="1920" height="10" src="/src/assets/Assets/images/line-1.jpg"
                                        class="attachment-full size-full" alt="" loading="lazy"
                                        sizes="(max-width: 1920px) 100vw, 1920px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style scoped>
nav ul {
    text-align: center;
}

nav ul li {
    display: inline-block;
}
.elementor-icon-list-text {
    padding-left: 5px;
}
</style>
