<script setup>
import Header from './pages/components/Header.vue';
import Footer from './pages/components/Footer.vue';
import Overlay from './pages/components/Overlay.vue';
import Toast from './pages/components/Toast.vue';
let banner = import.meta.env.VITE_APP_STAGING_BANNER;

</script>
<template>
  <v-app>
    <div>
      <div v-if="banner=='true'" class="d-flex justify-center" style="background-color:red; font-weight:bold; color:white">
        <marquee>This is Staging Environment!!</marquee>
      </div>
      <Overlay />
      <Toast />
      <router-view v-slot="{ Component, route }">
        <Header v-if="route.name !== 'Login' && route.name !== 'ResetPassword'"></Header>
        <transition name="fade" mode="out-in">
          <div :key="route.name">
            <component :is="Component"></component>
          </div>
        </transition>
        <Footer v-if="route.name !== 'Login' && route.name !== 'ResetPassword'"></Footer>
      </router-view>
    </div>
  </v-app>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
