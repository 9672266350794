import './index.css';
import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createPinia } from 'pinia';
import { setupInterceptors } from '@/axios';
import '@mdi/font/css/materialdesignicons.css'

const pinia = createPinia(); // Create the Pinia instance

let app = createApp(App);

const vuetify = createVuetify({
  components,
  directives,
});

app.use(pinia); 
app.use(router);
app.use(vuetify);

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA0Rx4E-BYlFldkYosPDom81hEcMVaa2fc',
    libraries: 'places',
  },
});

setupInterceptors(); 
app.mount('#app');