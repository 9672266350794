import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCommonStore = defineStore('commonStore', () => {
  const overlayVisible = ref(false);
  const overlayMessage = ref('');

  const showOverlay = (message) => {
    overlayVisible.value = true;
    overlayMessage.value = message;
  };

  const hideOverlay = () => {
    overlayVisible.value = false;
    overlayMessage.value = '';
  };

  return {
    overlayVisible,
    overlayMessage,
    showOverlay,
    hideOverlay,
  };
});
