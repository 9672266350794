// axios.js
import axios from 'axios';
import { useCommonStore } from '@/store/commonStore';



// Set up Axios with a base URL and credentials
const axiosInstance = axios.create({
    baseURL: `${import.meta.env.VITE_APP_API_HOST}/api/method/`,
    withCredentials: true,
});

// Wrap the axios interceptors setup in a function
const setupInterceptors = () => {
    const commonStore = useCommonStore();
    axiosInstance.interceptors.request.use(
        (config) => {
            if (config && config.blockui) {
                commonStore.showOverlay(config.overlayMessage);
            }
            return config;
        },
        (error) => {
            commonStore.hideOverlay();
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.config && response.config.blockui) {
                commonStore.hideOverlay();
            }
            return response;
        },
        (error) => {
            if (error.config && error.config.blockui) {
                commonStore.hideOverlay();
            }
            return Promise.reject(error);
        }
    );
};

export { axiosInstance, setupInterceptors };
