import { createRouter, createWebHistory } from 'vue-router'
import { session } from './data/session'
import { userResource } from '@/data/user'

const routes = [
  {
    path: '/:notFound',
    name: 'NotFound',
    component: import('@/pages/NotFound.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Dashboard.vue'),
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: () => import('@/pages/Dashboard.vue'),
  },
  {
    name: 'ShipmentTracking',
    path: '/shipment-tracking',
    component: () => import('@/pages/ShipmentTracking.vue'),
  },
  {
    name: 'Create Shipment',
    path: '/create-shipment',
    component: () => import('@/pages/CreateShipment.vue'),
  },
  {
    name: 'ShipmentHistory',
    path: '/shipment-history',
    component: () => import('@/pages/ShipmentHistory.vue'),
  },
  {
    name: 'RaiseQuery',
    path: '/raise-query',
    component: () => import('@/pages/RaiseQuery.vue'),
  },
  {
    name: 'CustomerProfile',
    path: '/customer-profile',
    component: () => import('@/pages/CustomerProfile.vue'),
  },
  {
    name: 'ShipmentCancellation',
    path: '/shipment-cancellation',
    component: () => import('@/pages/ShipmentCancellation.vue'),
  },
  {
    name: 'AddressBook',
    path: '/address-book',
    component: () => import('@/pages/AddressBook.vue'),
  },
  {
    name: 'UnbilledShipment',
    path: '/unbilled-shipment',
    component: () => import('@/pages/UnbilledShipment.vue'),
  },
  {
    name: 'OutstandingAmount',
    path: '/outstanding-amount',
    component: () => import('@/pages/OutstandingAmount.vue'),
  },
  {
    name: 'LedgerDetail',
    path: '/ledger-detail',
    component: () => import('@/pages/LedgerDetail.vue'),
  },
  {
    name: 'MyInvoices',
    path: '/my-invoices',
    component: () => import('@/pages/MyInvoices.vue'),
  },
  {
    name: 'SchedulePickup',
    path: '/schedule-pickup',
    component: () => import('@/pages/SchedulePickup.vue'),
  },
  {
    name: 'CancelPickup',
    path: '/cancel-pickup',
    component: () => import('@/pages/CancelPickup.vue'),
  },
  {
    name: 'ReschedulePickup',
    path: '/reschedule-pickup',
    component: () => import('@/pages/ReschedulePickup.vue'),
  },
  {
    name: 'DownloadForms',
    path: '/download-forms',
    component: () => import('@/pages/DownloadForms.vue'),
  },
  {
    name: 'RaiseRFQ',
    path: '/raise-rfq',
    component: () => import('@/pages/RaiseRFQ.vue'),
  },
  {
    name: 'RaiseTicket',
    path: '/raise-ticket',
    component: () => import('@/pages/RaiseTicket.vue'),
  },
  {
    name: 'NotificationDetail',
    path: '/notification-detail',
    component: () => import('@/pages/NotificationDetail.vue'),
  },
  {
    name: 'ChangePassword',
    path: '/change-password',
    component: () => import('@/pages/ChangePassword.vue'),
  },
  {
    name: 'ResetPassword',
    path: '/update-password',
    component: () => import('@/pages/ResetPassword.vue'),
  }
]

let router = createRouter({
  history: createWebHistory('/'),
  routes,
})

router.beforeEach(async (to, from, next) => {
  let isLoggedIn = session.isLoggedIn
  try {
    // if(isLoggedIn){
    //   await userResource.reload();
    // }
    await userResource.promise
  } catch (error) {
    isLoggedIn = false
  }
  
  if (to.name !== 'ChangePassword' && session.force_reset_password) {
    next({ name: 'ChangePassword' })
  } else if (to.name === 'ResetPassword' && !isLoggedIn) {
    next()
  } else if (to.name === 'Login' && isLoggedIn) {
    next({ name: 'Dashboard' })
  } else if (to.name !== 'Login' && !isLoggedIn) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
